import { api } from '@/shared/api'

const Products = {
  fetchProduct: async ID => {
console.log('products.fetchProduct', ID)
    let res = await api.get(`products/${ID}`)
console.log('products.response', res)
    return res
  },  
  fetchProducts: async payload => {

    console.log('products.fetchProducts', JSON.stringify(payload))
    let res = await api.post(`products`, payload)

    return res
  },
  saveProducts: async payload => {
    console.log('saveProducts', payload)

    let url = payload.ID ? `products/${payload.ID}` : `products`

    return api.put(url, payload)
  },
  deleteProduct: async ID => {
    return api.delete(`products/${ID}`)
  },
  fetchProductsCategories: async () => {
    let res = await api.get(`products/categories`)

    return res
  },
  fetchFinishedProducts: async () => {
    let res = await api.get(`products/finished-products`)

    return res
  },
  fetchBottleSizes: async () => {
    let res = await api.get(`dictionaries/bottle-sizes`)

    return res
  },
  fetchProductCustomPrices: async (payload) => {
    
    console.log('fetchProductCustomPrices.payload', payload)

    const res = await api.post('meta-models/product-custom-prices/search',payload);

    console.log('fetchProductCustomPrices.response', res)
    return res
  },
  saveProductCustomPrice: async (payload) => {

    console.log('saveProductCustomPrice', payload)

    const method = payload.ID ? 'put' : 'post';
    const res = await api[method]('meta-models/product-custom-prices', payload);

    return res
  },
  deleteProductCustomPrice: async (ID) => {

    console.log('deleteProductCustomPrice.ID', ID)
    return api.delete(`meta-models/product-custom-prices/${ID}`, {id:ID})
  },
  fetchProductStatsWHLTM: async (payload) => {

        const res = await api.post('meta-models/product-stats-wh-ltm/search', payload);

    return res
  },
  

}

export default Products
